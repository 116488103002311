import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { catchError, retry, shareReplay, retryWhen, delayWhen, scan } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  url = environment.url + '/test';

  testInfo: any = [];

  constructor(private http: HttpClient) { }


  TstCdPreSel(item) {
    return this.http.post<any>(`${this.url}/TstCdPreSel`, item, { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url) });
  }

  TstCdSel(item) {
    return this.http.post<any>(`${this.url}/TstCdSel`, item, { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url) });
  }

  TstDsatSrtAnsSel(item) {
    return this.http.post<any>(`${this.url}/TstDsatSrtAnsSel`, item, { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url) });
  }

  /* 테스트 섹션 업데이트 */
  TstSttUpd(item) {
    return this.http.post<any>(`${this.url}/TstSttUpd`, item, { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url) }).pipe(retryWhen(err => err.pipe(scan(retryCount => { if (retryCount > 5) throw err; else retryCount++; return retryCount; }, 0), delayWhen(() => timer(2000)))));
  }

  TstStpUpd(item) {
    return this.http.post<any>(`${this.url}/TstStpUpd`, item, { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url) });
  }

  /* voc2 cheat cnt */
  TstSttUpd2(item) {
    return this.http.post<any>(`${this.url}/TstSttUpd2`, item, { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url) });
  }

  /* 테스트 마무리 업데이트 */
  TstEndUpd(item) {
    return this.http.post<any>(`${this.url}/TstEndUpd`, item, { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url) });
  }

  /* Voc2 test */
  TstVocDtSel(item) {
    return this.http.post<any>(`${this.url}/TstVocDtSel`, item, { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url) });
  }

  SetTestInfo(args) {
    this.testInfo = args;
  }

  GetTestInfo() {
    return this.testInfo;
  }

  // private test = new Subject<object>();
  private test = new BehaviorSubject<object>([]);
  testSec = this.test.asObservable();

  SetTestParam(param) {
    this.test.next(param);
  }

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  tempTest() {
    console.log("clicked")
    return this.http.post<any>(`http://127.0.0.1:8058`, "^XA^A0R,65,38^FO700,900^FD APTIV ^FS^A0R,65,45^FO400,680^FDQty^FS^FO220,240^GB600,1,1,B,0^FS^A0R,65,45^FO550,265^FD11/08/2021^FS^FO370,0^GB1,1260,1,B,0^FS^FO670,4^GB1,1260,1,B,0^FS^A0R,65,45^FO400,265^FDAPT2110220001^FS^A0R,65,45^FO100,670^FDQC CHECK^FS^A0R,65,45^FO550,900^FDLP^FS^FO50,880^GB175,1,1,B,0^FS^A0R,65,45^FO700,680^FDSupplier^FS^FO370,880^GB600,1,1,B,0^FS^A0R,65,45^FO400,32^FDLot No^FS^A0R,65,45^FO250,265^FDUNIT ASSY-WIRELESS CHARGING (WLC,NFC)^FS^A0R,65,45^FO550,32^FDDate^FS^BY4,2.0^FO80,70^B7R,6,0,4,20,N^FH^FD APTIV :95560N9010:20:20211108:APT2110220001^FS^FO370,650^GB600,1,1,B,0^FS^FO795,0^GB60,1250,60^FS^FO50,650^GB175,1,1,B,0^FS^FO2,2^GB810,1230,3^FS^A0R,65,45^FO250,30^FDPart Name^FS^FO520,0^GB1,1260,1,B,0^FS^A0R,65,45^FO700,32^FDPart No^FS^FO0,0^GB60,1250,60^FS^A0R,65,45^FO550,680^FDType^FS^A0R,65,45^FO400,900^FD20 EA^FS^A0R,35,35^FO10,415^FR^FDKwangSung America Corporation.^FS^FO220,0^GB1,1260,1,B,0^FS^A0R,65,45^FO700,265^FD95560-N9010^FS^PQ1,0,1,Y^XZ", { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url).set("Access-Control-Allow-Origin", "*").set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept") });
  }
}
