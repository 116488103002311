import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-annou',
  templateUrl: './annou.component.html',
  styleUrls: ['./annou.component.css']
})
export class AnnouComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
