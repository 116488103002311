import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    //    url = environment.url + '/test';

    constructor(private http: HttpClient) { }

    getIpAddr() {
        //return this.http.get("https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=json");//, { headers: new HttpHeaders().set("Access-Control-Allow-Origin", "*").set("Access-Control-Allow-Headers","Origin,Accept,X-Requested-With,Content-Type,Access-Control-Request-Method,Access-Control-Request-Headers,Authorization") });
        return this.http.get("https://www.cloudflare.com/cdn-cgi/trace");//, { headers: new HttpHeaders().set("responseType", "'text' as 'json") });
    }

    setInfoList(list) {
        let tempList = [];

      }
}