<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 pad-lr-0 pad-top-15" style="text-align: center;" (click)="navTo('/registration/class')" style="cursor: grab;">
            <img type="image/png" src="./Resources/Images/main.png" style="width:100%; height: auto">
        </div>
        <br />
        <!-- <div class="col-lg-4 col-md-6 col-12 pad-tb-15">
            <div class="panel_container" style="border:solid 4px rgb(242, 152, 54);">
                <div><img type="image/jpeg" src="./Resources/Images/anouncement.jpeg" class="panel_image"></div>
                <div class="panel_title" style="background-color: rgb(242, 152, 54);"> Anouncement
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12 pad-tb-15">
            <div class="panel_container" style="border:solid 4px rgb(242, 152, 54);">
                <div><img type="image/jpeg" src="./Resources/Images/study.jpeg" class="panel_image"></div>
                <div class="panel_title" style="background-color: rgb(242, 152, 54);"> About </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12 pad-tb-15">
            <div class="panel_container" style="border:solid 4px rgb(242, 152, 54);">
                <div><img type="image/jpeg" src="./Resources/Images/paper.jpeg" class="panel_image"></div>
                <div class="panel_title" style="background-color: rgb(242, 152, 54);"> Contact </div>
            </div>
        </div> -->
        <div class="col-md-4 col-12 pad-tb-15">
            <div class="panel_container" (click)="navTo('/registration/class')" style="cursor: grab;">
                <div><img type="image/jpeg" src="./Resources/Images/paper.jpeg" class="panel_image"></div>
                <div class="panel_title" style="background-color: rgb(39, 70, 125);"> Registration </div>
            </div>
        </div>
        <div class="col-md-4 col-12 pad-tb-15">
            <div class="panel_container"  style="cursor: grab;">
                <div><img type="image/jpeg" src="./Resources/Images/test.jpeg" class="panel_image"></div>
                <div class="panel_title" style="background-color: rgb(39, 70, 125);"> Academics </div>
            </div>
        </div>
        <div class="col-md-4 col-12 pad-tb-15">
            <div class="panel_container" (click)="navTo('/test')"  style="cursor: grab;">
                <div><img type="image/jpeg" src="./Resources/Images/appointment.jpeg" class="panel_image"></div>
                <div class="panel_title" style="background-color: rgb(39, 70, 125);"> Test </div>
            </div>
        </div>
        <!-- <div class="col-lg-4 col-md-6 col-12 pad-tb-15">
            <div class="panel_container" style="border:solid 4px rgb(39, 70, 125);">
                <div><img type="image/jpeg" src="./Resources/Images/appointment.jpeg" class="panel_image"></div>
                <div class="panel_title" style="background-color: rgb(39, 70, 125);"> Appointment </div>
            </div>
        </div> -->
    </div>
</div>