<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 title" (click)="test()">
            Contact us
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-6 col-sm-10 col-12" style="justify-content: center; display: flex">
            <div class="card bg-light mb-3" style="width: 400px">
                <!-- <div class="card-header">Header</div> -->
                <div class="card-body">
                    <div class="c-container">
                        <p style="margin: 7px 0px">
                            <a href="https://goo.gl/maps/z3cDxbPz9FwmdunU7" target="_blank">
                                <i class="fa fa-building-o n-icon"></i>370 Leavell Cir, Montgomery, AL 36117</a>
                        </p>

                        <p style="margin: 7px 0px">
                            <a href="tel:+1-334-86803520" rel="nofollow">
                                <i class="fa fa-phone n-icon"></i>334-868-3520</a>
                        </p>

                        <p style="margin: 7px 0px">
                            <a href="mailto:hanacademy2011@gmail.com">
                                <i class="fa fa-envelope-o n-icon"></i>hanacademy2011@gmail.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center" style="flex-grow: 1; min-height: 200px; margin-top:20px">
        <div class="iframe-container">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3371.026971075865!2d-86.15821049733191!3d32.33798549594351!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6377e414a7e40e1!2sHan%20Academy%20LLC!5e0!3m2!1sen!2sus!4v1588798530423!5m2!1sen!2sus" width="400" height="300" frameborder="0" allowfullscreen="" aria-hidden="false"
                tabindex="0" style="border: 0;"></iframe>
        </div>
    </div>
</div>