import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from "@angular/common";


import { AppRoutingModule } from './app-routing.module';
import { Routes, RouterModule, RouteReuseStrategy } from '@angular/router';

import { NgProgressModule } from '@ngx-progressbar/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { AppComponent } from './app.component';
import { WebNavComponent } from './web/web-nav/web-nav.component';
import { WebHomeComponent } from './web/web-home/web-home.component';

import { AboutComponent } from './web/about/about.component';
import { AcadeComponent } from './web/acade/acade.component';
import { FallComponent } from './web/acade/fall/fall.component';
import { SpringComponent } from './web/acade/spring/spring.component';
import { SummerComponent } from './web/acade/summer/summer.component';

import { AnnouComponent } from './web/annou/annou.component';

import { CalenComponent } from './web/calen/calen.component';
import { ContaComponent } from './web/conta/conta.component';
import { AppoiComponent } from './web/appoi/appoi.component';

import { RegisComponent } from './web/regis/regis.component';
import { NewComponent } from './web/regis/new/new.component';
import { ClassComponent } from './web/regis/class/class.component';


const routes: Routes = [
  {
    path: '', component: WebNavComponent,
    children: [
      { path: '', component: WebHomeComponent },

      { path: 'about', component: AboutComponent },
      { path: 'academics', component: AcadeComponent },
      { path: 'academics/fall', component: FallComponent },
      { path: 'academics/spring', component: SpringComponent },
      { path: 'academics/summer', component: SummerComponent },

      { path: 'announcement', component: AnnouComponent },

      { path: 'test', loadChildren: () => import('./web/test/test.module').then(m => m.TestModule) },

      { path: 'calendar', component: CalenComponent },
      { path: 'contact', component: ContaComponent },
      // { path: 'appointment', component: AppoiComponent },
      { path: 'registration', component: RegisComponent },
      { path: 'registration/new', component: NewComponent },
      { path: 'registration/class', component: ClassComponent },
      //  { path: 'test', loadChildren:       }
    ]
  }
]

@NgModule({
  declarations: [
    AppComponent,
    WebNavComponent,
    WebHomeComponent,

    AboutComponent,
    AcadeComponent,
    FallComponent,
    SpringComponent,
    SummerComponent,

    AnnouComponent,

    CalenComponent,
    ContaComponent,
    // AppoiComponent,

    RegisComponent,
    NewComponent,
    // MaskNoDirective,

    ClassComponent,
  ],
  imports: [
    RouterModule.forRoot(routes),
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    // AppRoutingModule,
    NgxExtendedPdfViewerModule,

    NgProgressModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [],
  //exports:[RouterModule],

  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }