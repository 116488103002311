import { Component, OnInit } from '@angular/core';
import { RegService } from 'src/app/service/reg.service';

@Component({
  selector: 'app-class',
  templateUrl: './class.component.html',
  styleUrls: ['./class.component.css']
})
export class ClassComponent implements OnInit {

  sprLink: any = "";
  sumLink: any = "";
  falLink: any = "";
  sprName: any = "";
  sumName: any = "";
  falName: any = "";
  constructor(private regSerivce: RegService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.regSerivce.RegClsLinkSel({}).subscribe(
        res => {
          console.log(res.Table)
          if (res.Table && res.Table.length > 0) {
            res.Table.forEach((row, i) => {
              if (row["CLS_LK"] != null && row["CLS_LK"].toString() != "") {
                if (row["CLS_TP"].toString() == "FAL") { this.falLink = row["CLS_LK"].toString(); }
                if (row["CLS_TP"].toString() == "SUM") { this.sumLink = row["CLS_LK"].toString(); }
                if (row["CLS_TP"].toString() == "SPR") { this.sprLink = row["CLS_LK"].toString(); }
              }

              if (row["CLS_NM"] != null && row["CLS_NM"].toString() != "") {
                if (row["CLS_TP"].toString() == "FAL") { this.falName = row["CLS_NM"].toString(); }
                if (row["CLS_TP"].toString() == "SUM") { this.sumName = row["CLS_NM"].toString(); }
                if (row["CLS_TP"].toString() == "SPR") { this.sprName = row["CLS_NM"].toString(); }
              }
            });
          }
        },
        err => { }
      )
    });
  }

  goToLink(type) {

    if (type == "spr" && this.sprLink != null) {
      window.open(this.sprLink);
      //window.open("https://forms.gle/cneTNcqDtbKvrWnW6");
    }
    else if (type == "sum" && this.sumLink != null) {
      window.open(this.sumLink);
      // window.open("https://forms.gle/qgBefrq2uJNaHw81A");
    }
    else if (type == "fal" && this.sumLink != null) {
      window.open(this.falLink);
      // window.open("https://forms.gle/dYmnsNrAAFXW1kka8");
    }
  }
}
