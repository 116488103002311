import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegService {

  url = environment.url + '/reg';

  testInfo: any = [];

  constructor(private http: HttpClient) { }


  RegFormInfoSel(item) {
    return this.http.post<any>(`${this.url}/RegFormInfoSel`, item, { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url) });
  }

  RegFormStuIns(item){
    return this.http.post<any>(`${this.url}/RegFormStuIns`, item, { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url) });
  }

  RegClsLinkSel(item) {
    return this.http.post<any>(`${this.url}/RegClsLinkSel`, item, { headers: new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem("jwt")).set('version', environment.url) });
  }
}
