import { Component, OnInit, ChangeDetectionStrategy, HostListener, ElementRef, ViewEncapsulation } from '@angular/core';
import { NgxExtendedPdfViewerService, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
  selector: 'app-spring',
  templateUrl: './spring.component.html',
  styleUrls: ['./spring.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SpringComponent implements OnInit {
  type: any = "kor";
  url: any = "./Resources/files/korSpring.pdf";

  spreadMode: 'off' | 'even' | 'odd' = 'off';
  page: any;
  constructor(private elRef: ElementRef) {
    //pdfDefaultOptions.assetsFolder = 'bleeding-edge';
  }

  @HostListener('window:keydown', ['$event'])
  onKeyPress($event: KeyboardEvent) {
    console.log($event.ctrlKey)
    console.log($event.keyCode)
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 83) {
      // console.log('CTRL + S');
      $event.preventDefault();
      $event.stopPropagation();
      return;
    }
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 80) {
      // console.log('CTRL + P');
      $event.preventDefault();
      $event.stopPropagation();
      return;
    }
  }

  ngOnInit() {
    
  }

  onChange($event, type) {
    if (type == "kor") {
      console.log("korean");
      this.type = "kor";
      this.url = "./Resources/files/korSpring.pdf?version=${new Date().getTime()}`;";
    }
    else {
      console.log("english");
      this.type = "eng";
      this.url = "./Resources/files/engSpring.pdf?version=${new Date().getTime()}`;";
    }
    window.scrollTo(0, document.body.scrollHeight);
  }


  blur() {
    console.log("clicked");
    this.elRef.nativeElement.blur();
  }
}
