import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { fromEvent } from 'rxjs';
import { NgProgressComponent } from '@ngx-progressbar/core';
import { CommonService } from './service/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'HanWeb';

  @ViewChild(NgProgressComponent) progressBar: NgProgressComponent;

  constructor(private router: Router, private commonService: CommonService) { }

  ngOnInit() {
    this.getIpAddr();
    this.getBrowserVersion();

    // const visibilityChange$ = fromEvent(document, 'visibilitychange');
    // visibilityChange$.subscribe(() => {
    //   if (document.hidden) {
    //     // User switched tabs or navigated away
    //     // Perform the necessary actions or show a message
    //     console.log('User switched tabs or navigated away');
    //   } else {
    //     // User returned to the tab
    //     // Perform the necessary actions or show a message
    //     console.log('User returned to the tab');
    //   }
    // });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      console.log("FOOTER HEIGHT", document.getElementById('footer').offsetHeight);
      document.getElementById('shadow').style.height = document.getElementById('footer').offsetHeight + 20 + "px";
      //    document.getElementById('timer').style.height = document.getElementById('footer').offsetHeight + "px";
    });
  }

  start() {
    this.progressBar.start();

  }

  done() {
    this.progressBar.complete();
  }

  click() {
  }

  getIpAddr() {
    this.commonService.getIpAddr().subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.log("err");
        console.log(err)

        let txt = err.error.text;
        txt = txt.split("\n").filter(el => el.startsWith("ip")).join('\n');// || el.startsWith("uag")).join('\n'));
        if (txt && txt.length > 0) {
          // let ip = txt.match(/(?<==)[^\]]+/i);
          // localStorage.setItem("ip", ip[0]);
          let ip = txt.match(/^(?:ip|uag)=(.*)$/i);

          localStorage.setItem("ip", ip[1]);
          return;
        }
        localStorage.setItem("ip", "unknown");
      }
    );
  }

  getBrowserVersion() {
    let browser = 'unknown';
    let ua = navigator.userAgent;
    let idx = -1;
    if (ua.indexOf("Opera") != -1 || ua.indexOf('OPR') != -1) {
      browser = 'Opera'
      idx = ua.indexOf("Opera") != -1 ? ua.indexOf("Opera") : ua.indexOf('OPR');
    }
    else if (ua.indexOf("Edge") != -1 || ua.indexOf("Edg") != -1) {
      browser = 'Edge'
      idx = ua.indexOf("Edge") != -1 ? ua.indexOf("Edge") : ua.indexOf("Edg");
    }
    else if (ua.indexOf("Chrome") != -1) {
      browser = 'Chrome'
      idx = ua.indexOf("Chrome");
    }
    else if (ua.indexOf("Safari") != -1) {
      browser = 'Safari'
      idx = ua.indexOf("Safari");
    }
    else if (ua.indexOf("Firefox") != -1) {
      browser = 'Firefox'
      idx = ua.indexOf("Firefox");
    }
    else if ((ua.indexOf("MSIE") != -1) || document['documentMode'] == true) //IF IE > 10
    {
      browser = 'IE'
      idx = ua.indexOf("MSIE");
    }
    else {
      browser = 'unknown'
    }
    let ver = "";
    let fVer;
    if (idx != -1) {
      ver = ua.substr(idx);
      fVer = ver.match(/(^[A-Z])\w+\/{1}(\d+[^\s]+)/i);
      localStorage.setItem("br", fVer[0]);
      return;
    }
    localStorage.setItem("br", "unknown");
    ;
  }
}
