import { trigger, state, style, animate, transition, query, stagger, group } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare let $: any;

@Component({
  selector: 'app-web-nav',
  templateUrl: './web-nav.component.html',
  styleUrls: ['./web-nav.component.css'],
  animations: [
    trigger('openSlide', [
      state('open', style({ 'height': '*', 'opacity': '1', 'visibility': 'visible' })),
      state('close', style({ 'height': '0px', 'opacity': '0', 'visibility': 'hidden' })),
      transition('open => close', [style({ 'height': '*', transform: 'translateY(0%)' }), animate('600ms ease-out', style({ transform: 'translateY(0%)', 'opacity': '0', 'visibility': 'hidden', 'height': '0px' }))]),
      transition('close => open', [style({ 'height': '*', transform: 'translateY(-10%)' }), animate('700ms ease-out', style({ transform: 'translateY(0%)', 'opacity': '1', 'visibility': 'visible', 'height': '*' }))])
    ]),
  ]
})
export class WebNavComponent implements OnInit {
  menuOpen: any = false;

  acaSub: boolean = false;
  regSub: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
  }

  home() {
    this.takeMe();
  }

  takeMe() {
    this.router.navigate(['']);
  }

  navTo(args) {

    this.acaSub = false;
    this.regSub = false;
    this.router.navigate([args], { relativeTo: this.route });
    $('#navbarSupportedContent').collapse('hide');
    $('#SubAca').collapse('hide');
    $('#SubReg').collapse('hide');

  }

  openSub(args) {
    if (args == '/academics') {
      this.acaSub = !this.acaSub ? false : true;
    }
    else if (args == '/registration') {
      this.regSub = !this.regSub;

    }
  }
}
