import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TestService } from 'src/app/service/test.service';

@Component({
  selector: 'app-conta',
  templateUrl: './conta.component.html',
  styleUrls: ['./conta.component.css']
})
export class ContaComponent implements OnInit {

  src: any = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3371.026971075865!2d-86.15821049733191!3d32.33798549594351!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6377e414a7e40e1!2sHan%20Academy%20LLC!5e0!3m2!1sen!2sus!4v1588798530423!5m2!1sen!2sus";
  constructor(private sanitizer: DomSanitizer, private testService: TestService) { }

  ngOnInit(): void {
    this.getGglMap();
  }

  getGglMap() {
    //console.log(this.sanitizer.bypassSecurityTrustUrl(this.src));
    return this.src;
  }

  test() {
    this.testService.tempTest().subscribe(
      res => { }
      , err => { }
    )
  }
}
