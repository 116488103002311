import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acade',
  templateUrl: './acade.component.html',
  styleUrls: ['./acade.component.css']
})
export class AcadeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
