import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regis',
  templateUrl: './regis.component.html',
  styleUrls: ['./regis.component.css']
})
export class RegisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
