import { Component, ComponentFactoryResolver, Directive, ElementRef, HostListener, Injector, OnInit, Optional, Self, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgControl, Validators } from '@angular/forms';
import { RegService } from 'src/app/service/reg.service';
import { pairwise, startWith } from 'rxjs/operators';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.css']
})
export class NewComponent implements OnInit {

  stuInfo: FormGroup;
  formBuilder: FormBuilder;
  chkConsent: boolean = false;

  p_relList: any[] = [{ val: 'FA', dip: 'Father' }, { val: 'MO', dip: 'Mother' }, { val: 'SI', dip: 'Sibiling' }, { val: 'OT', dip: 'Other' }];
  infoList: any = [];

  dob: any;
  curDate: any;
  datePickerConfig?: Partial<BsDatepickerConfig>;

  @ViewChild("newForm", { static: true }) newForm: ElementRef;
  @ViewChild("p_ot", { static: true }) p_ot: ElementRef;
  @ViewChild("e_ot", { static: true }) e_ot: ElementRef;

  old: any;

  constructor(private regService: RegService) {
    this.regFormInfoSel();
  }

  ngOnInit() {
    this.curDate = new Date();
    this.curDate.setFullYear(this.curDate.getFullYear() - 7);

    this.datePickerConfig = Object.assign({}, {
      containerClass: 'theme-dark-blue',
      dateInputFormat: 'MM/DD/YYYY',
      placement: 'bottom',
      showWeekNumbers: false,
      selectFromOtherMonth: true
    })

    this.stuInfo = new FormGroup({
      fst_nm_en: new FormControl("", Validators.required),
      lst_nm_en: new FormControl("", Validators.compose([Validators.required])),

      fst_nm_kr: new FormControl(""),//, Validators.compose([Validators.required])),
      lst_nm_kr: new FormControl(""),//, Validators.compose([Validators.required])),

      gndr: new FormControl("M", Validators.compose([Validators.required])),
      grade: new FormControl("", Validators.compose([Validators.required, Validators.pattern("\d{1,2}|^K\d{1}$")])),
      schl: new FormControl(null, Validators.compose([Validators.required])),
      dob: new FormControl(this.curDate, Validators.compose([Validators.required, Validators.pattern("/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/")])),
      addr: new FormControl("", Validators.compose([Validators.required])),
      ph_no: new FormControl("", Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),

      p_fst_nm_en: new FormControl("", Validators.compose([Validators.required])),
      p_lst_nm_en: new FormControl("", Validators.compose([Validators.required])),

      p_rel: new FormControl("MO", Validators.compose([Validators.required])),
      p_email: new FormControl("", Validators.compose([Validators.required, Validators.pattern("\A[A-Z0-9+_.-]+@[A-Z0-9.-]+\Z")])),
      p_ph_no: new FormControl("", Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")])),
      p_occu: new FormControl(""),

      e_fst_nm_en: new FormControl("", Validators.compose([Validators.required])),
      e_lst_nm_en: new FormControl("", Validators.compose([Validators.required])),
      e_rel: new FormControl("FA", Validators.compose([Validators.required])),
      e_ph_no: new FormControl("", Validators.compose([Validators.required, Validators.pattern("^[0-9]*$")]))
    });

    //document.getElementById("contentChk").setAttribute('disabled', 'disabled');
    // this.stuInfo.get('no')
    //   .valueChanges
    //   .pipe(startWith(null as string), pairwise())
    //   .subscribe(([prev, next]: [any, any]) =>
    //   console.log(prev)
    //   );

    this.keepNumberOnly('ph_no');
    this.keepNumberOnly('p_ph_no');
    this.keepNumberOnly('e_ph_no');
  }

  get s(){
    return this.stuInfo.controls;
  }

  AfterViewInit() {
    setTimeout(() => {
      // window.scrollTo(0, 0);
      document.body.scrollTop = 0;

      // this.old = { ...this.stuInfo.value }
      // this.stuInfo.valueChanges.subscribe(res => {
      //   console.log(res)
      //   const key = Object.keys(res).find(k => res[k] != this.old[k])
      //   console.log(key)
      //   this.old = { ...this.stuInfo.value }
      // });
    });
  }


  regFormInfoSel() {
    this.regService.RegFormInfoSel({}).subscribe(
      res => {
        if (res.Table && res.Table.length > 0) {
          this.infoList = res.Table;
        }
      },
      err => {

      }
    )
  }

  chgRel(args) {
    switch (args) {
      case 'p':
        if (this.stuInfo.get("p_rel").value == "OT") {
          this.p_ot.nativeElement.style.display = 'block';
        }
        else {
          this.p_ot.nativeElement.style.display = 'none';
        }
        break;
      case 'e':
        if (this.stuInfo.get("e_rel").value == "OT") {
          this.e_ot.nativeElement.style.display = 'block';
        }
        else {
          this.e_ot.nativeElement.style.display = 'none';
        }
        break;
    }
  }

  scrollMove($event) {
    if ($event.target.offsetHeight + $event.target.scrollTop >= $event.target.scrollHeight) {
      document.getElementById("contentChk").removeAttribute("disabled");
    }
  }

  chkChange($event) {
    this.chkConsent = this.chkConsent ? false : true;
    console.log(this.chkConsent);
  }

  onDateChange(res) {
    if (res == "Invalid Date") {
      this.stuInfo.controls.dob.setValue(this.curDate);
    }
  }

  isDisable() {
    return {}
  }

  get stuInfoControls() {
    //console.log(this.stuInfo.controls)
    return this.stuInfo.controls;
  }

  onSubmit(data) {
    // console.log(this.stuInfo.valid)
    // console.log(this.stuInfo.invalid)
    // console.log(this.stuInfo.dirty)

    // console.log(data);
    if (this.stuInfo.invalid) {
      this.validateAllFormFields(this.stuInfo);

    }
    let t_dob = this.stuInfo.value.dob
      ? this.stuInfo.value.dob.getFullYear() + ("0" + (this.stuInfo.value.dob.getMonth() + 1)).slice(-2) + ("0" + this.stuInfo.value.dob.getDate()).slice(-2)
      : this.stuInfo.value.dob;


    this.stuInfo.addControl('t_dob', new FormControl(t_dob, Validators.required));

    console.log(this.stuInfo.value);

    let tempList = this.stuInfo.value;
    //tempList = Object.assign(tempList, {test: 123});

    console.log(tempList)
    this.regService.RegFormStuIns(tempList).subscribe(
      res => { },
      err => { }
    );

  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }



  keepNumberOnly(args) {
    this.stuInfo
      .get(args)
      .valueChanges
      .pipe(startWith(null as string), pairwise())
      .subscribe(([prev, next]: [any, any]) => {
        if (prev != next) {
          let t_val = this.stuInfo.get(args).value;
          t_val = t_val.replace(/\D/g, '');
          this.stuInfo.patchValue({ [args]: t_val })
        }
      }
      );
    //myString = myString.replace(/\D/g,'');

  }
}


// @Directive({
//   selector: '[formControlName][no],[formControlName][p_no],[formControlName][e_no]',
// })
// export class MaskNoDirective {

//   constructor(public ngControl: NgControl) { }

//   @HostListener('ngModelChange', ['$event'])
//   onModelChange(event) {
//     console.log(event)
//     console.log(this.ngControl)
//     this.onInputChange(event, false);
//   }

//   @HostListener('keydown.backspace', ['$event'])
//   onKeydownBackspace(event) {
//     console.log(event)
//     this.onInputChange(event.target.value, true);
//   }

//   onInputChange(event, backspace) {
//     let newVal = event.replace(/\D/g, '');
//     if (backspace && newVal.length <= 6) {
//       newVal = newVal.substring(0, newVal.length - 1);
//     }
//     if (newVal.length === 0) {
//       newVal = '';
//     } else if (newVal.length <= 3) {
//       newVal = newVal.replace(/^(\d{0,3})/, '($1)');
//     } else if (newVal.length <= 6) {
//       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
//     } else if (newVal.length <= 10) {
//       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
//     } else {
//       newVal = newVal.substring(0, 10);
//       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
//     }
//     //console.log(newVal)
//     //this.ngControl.valueAccessor.writeValue(newVal);
//   }
// }