<div class="container">
    <div class="row justify-content-center">
        <div class="col-12" style="margin-bottom: 10px;">
            <div class="title" style="margin-bottom: 10px;">Summer Semester</div>
            <div class="form-inline" style="display: block !important">
                <div class="radio-toolbar2">
                    <div class="cell">
                        <input type="radio" id="RadioKor" value="KOR" name="radio-type"
                            (change)="onChange($event, 'kor')" required checked>
                        <label for="RadioKor">Korean</label>
                    </div>
                    <div class="cell">
                        <input type="radio" id="RadioEng" value="Eng" name="radio-type"
                            (change)="onChange($event, 'eng')">
                        <label for="RadioEng">English</label>
                    </div>
                </div>
            </div>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-md-9 col-sm-10 col-12" oncontextmenu="return false;">
        <ngx-extended-pdf-viewer 
            [src]="url"
            [zoom]="'auto'"
            [height]="'105%'" 
            [useBrowserLocale]="false" 
            [textLayer]="false" 
            [showToolbar]="true"
            [showSidebarButton]="false"
            [showFindButton]="false"
            [showPagingButtons]="true"
            [showZoomButtons]="true"
            [showPresentationModeButton]="false"
            [showOpenFileButton]="false"
            [showPrintButton]="false"
            [showDownloadButton]="false"
            [showBookmarkButton]="false"
            [showSecondaryToolbarButton]="false"
            [showRotateButton]="false"
            [showHandToolButton]="false"
            [showScrollingButton]="false"
            [showSpreadButton]="false"
            [showPropertiesButton]="false"       
            
            [enablePrint]="false"
            [ignoreKeyboard]="false"
            [ignoreKeys]="['CTRL+S', 'CTRL+P']"
            >
        </ngx-extended-pdf-viewer>
    </div>
</div>
</div>