<div class="container">
    <div class="row">
        <div class="col pad-lr-0">
            <nav class="navbar pad-0 navbar-expand-lg navbar-light" style="height:100px !important">
                <img class="nav-brand" type="image/png" src="./Resources/Images/Han.png"
                    style="width:95px; height:auto; padding: 10px; cursor: pointer;" (click)="home()" />
                <div class="collapse navbar-collapse">
                    <div class="ib" style="cursor: pointer;" (click)="home()">
                        <div class="m-title" style="color: #C00000;"> HAN</div>
                        <div class="m-title">&nbsp;Academy </div>
                    </div>
                    <div class="s-title ib" style="color: #808080; margin-top: 15px;">
                        &nbsp;&nbsp;SAT, ACT, English, Math, AP, Korean</div>
                </div>
                <div class="mr-auto"></div>
                <div class="float-right" style="display:inline-block; padding-right: 10px">
                    <a class="n-title" href="tel:+1-334-868-3520" rel="nofollow">
                        <i class="fa fa-phone n-icon"></i>(334) 868-3520</a>
                </div>
                <!-- <button type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
                    class="navbar-toggler navbar-toggler-btn"><i class="fa fa-bars"></i></button> -->

                <a class="navbar-toggler collapsed border-0" type="button" data-toggle="collapse"
                    data-target="#navbarSupportedContent" style="border-radius: 0%;">
                    <span> </span>
                    <span> </span>
                    <span> </span>
                </a>
                <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button> -->
            </nav>
            <div class="navbar-expand-lg navbar-light nav-top-color ">
                <div class="navbar collapse navbar-collapse pad-0" id="navbarNavAltMarkup">
                    <div class="navbar-nav navDist w-100 pad-0">
                        <!-- <a class="nav-link active" href="#">Home <span class="sr-only">(current)</span></a> -->
                        <div class="align-self-center nav-mark pad-15" (click)="navTo('/about')">ABOUT</div>
                        <!-- <div class="align-self-center nav-mark pad-15 dropdown">
                            <div id="navbarDropdownMenuLink" (click)="navTo('/academics')">ACADEMICS</div>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <div class="dropdown-item" (click)="navTo('/academics/fall')">Fall Program</div>
                                <div class="dropdown-item" (click)="navTo('/academics/spring')">Spring Program</div>
                                <div class="dropdown-item" (click)="navTo('/academics/summer')">Summer Camp</div>
                            </div>
                        </div> -->
                        <div class="align-self-center nav-mark pad-15 dropdown">
                            <div>ACADEMICS</div>
                            <div class="dropdown-content">
                                <div (click)="navTo('/academics/fall')">Fall Program</div>
                                <div (click)="navTo('/academics/spring')">Spring Program</div>
                                <div (click)="navTo('/academics/summer')">Summer Camp</div>
                            </div>
                        </div>

                        <div class="align-self-center nav-mark pad-15 dropdown">
                            <div (click)="navTo('/registration')">REGISTRATION</div>
                            <div class="dropdown-content">
                                <div (click)="navTo('/registration/new')">New Student</div>
                                <div (click)="navTo('/registration/class')">Class Registration</div>
                            </div>
                        </div>
                        <div class="align-self-center nav-mark pad-15" (click)="navTo('/announcement')">
                            ANNOUNCEMENT
                        </div>
                        <div class="align-self-center nav-mark pad-15" (click)="navTo('/test')">TEST</div>
                        <div class="align-self-center nav-mark pad-15" (click)="navTo('/calendar')">CALENDAR</div>
                        <div class="align-self-center nav-mark pad-15" (click)="navTo('/contact')">CONTACT</div>
                        <!-- <div class="align-self-center nav-mark pad-15" (click)="navTo('/registration')">REGISTRATION
                        </div> -->

                    </div>
                </div>
            </div>
            <!-- Mobile Menu -->
            <div class="mar-lr-5 navbar-expand-lg navbar-light nav-top-color" style="border-radius: 10px;">
                <div class="navbar pad-0 collapse" id="navbarSupportedContent">
                    <div class="navbar-nav navDist w-100 pad-tb-15">
                        <!-- <a class="nav-link active" href="#">Home <span class="sr-only">(current)</span></a> -->
                        <div class="pad-10 pad-top-0 pad-left-20 pad-bottom-0 nav-mark" data-toggle="collapse"
                            data-target="#navbarSupportedContent" (click)="navTo('/about')">ABOUT</div>
                        <div class="pad-10 pad-left-20 pad-bottom-0 nav-mark" data-toggle="collapse"
                            data-target="#SubAca" (click)="openSub('/academics')">ACADEMICS</div>
                        <div class="navbar pad-0 collapse pad-left-30 pad-bottom-0" id="SubAca"
                            style="background-color: #c09000;">
                            <div (click)="navTo('/academics/fall')">Fall Program</div>
                            <div (click)="navTo('/academics/spring')">Spring Program</div>
                            <div (click)="navTo('/academics/summer')">Summer Camp</div>
                        </div>
                        <div class="pad-10 pad-left-20 pad-bottom-0 nav-mark" data-toggle="collapse"
                            data-target="#SubReg" (click)="openSub('/registration')">REGISTRATION</div>
                        <div class="navbar pad-0 collapse pad-left-30" id="SubReg" style="background-color: #c09000;">
                            <div (click)="navTo('/registration/new')">New Student</div>
                            <div (click)="navTo('/registration/class')">Class Registration</div>
                        </div>
                        <div class="pad-10 pad-left-20 pad-bottom-0 nav-mark" data-toggle="collapse"
                            data-target="#navbarSupportedContent" (click)="navTo('/announcement')">ANNOUNCEMENT</div>
                        <div class="pad-10 pad-left-20 pad-bottom-0 nav-mark" data-toggle="collapse"
                            data-target="#navbarSupportedContent" (click)="navTo('/test')">TEST</div>
                        <div class="pad-10 pad-left-20 pad-bottom-0 nav-mark" data-toggle="collapse"
                            data-target="#navbarSupportedContent" (click)="navTo('/calendar')">CALENDAR</div>
                        <div class="pad-10 pad-left-20 pad-bottom-0 nav-mark" data-toggle="collapse"
                            data-target="#navbarSupportedContent" (click)="navTo('/contact')">CONTACT</div>
                        <!-- <div class="pad-10 pad-left-20 nav-mark" data-toggle="collapse"
                            data-target="#navbarSupportedContent" (click)="navTo('/appointment')">APPOINTMENT</div> -->

                        <!-- <div class="pad-10 pad-left-20 nav-mark" data-toggle="collapse"
                            data-target="#navbarSupportedContent" (click)="navTo('/registration')">REGISTRATION</div> -->
                    </div>
                </div>
            </div>
            <div id="scrollTo"></div>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>