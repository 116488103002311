<div class="container">
    <div class="row justify-content-center">
        <div class="col-12 title">
            Class Registeration
        </div>
    </div>
    <br />
    <div class="row justify-content-center">
        <div *ngIf="sprLink != null && sprLink != ''" class="col-md-6 col-sm-10 col-12"
            style="text-align: center;justify-content: center;">
            <br />
            <input type="button" class="btn btn-primary btn-lg" (click)="goToLink('spr')"
            [value]="sprName">
            <br />
        </div>
        <div *ngIf="falLink != null && falLink != ''" class="col-md-6 col-sm-10 col-12"
            style="text-align: center;justify-content: center;">
            <br />
            <input type="button" class="btn btn-primary btn-lg" (click)="goToLink('fal')" [value]="falName">
            <br />

        </div>
        <div *ngIf="sumLink != null && sumLink != ''" class="col-md-6 col-sm-10 col-12"
            style="text-align: center;justify-content: center;">
            <br />
            <input type="button" class="btn btn-primary btn-lg" (click)="goToLink('sum')"
            [value]="sumName">
            <br />
        </div>
    </div>
</div>